@font-face {
font-family: '__author_8f701b';
src: url(/_next/static/media/5e32791acef9d6ea-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__author_Fallback_8f701b';src: local("Arial");ascent-override: 90.05%;descent-override: 21.70%;line-gap-override: 8.68%;size-adjust: 92.17%
}.__className_8f701b {font-family: '__author_8f701b', '__author_Fallback_8f701b'
}.__variable_8f701b {--font-author: '__author_8f701b', '__author_Fallback_8f701b'
}

@font-face {
font-family: '__satoshi_ba7a58';
src: url(/_next/static/media/867bce6efedfde96-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__satoshi_Fallback_ba7a58';src: local("Arial");ascent-override: 92.36%;descent-override: 21.95%;line-gap-override: 9.14%;size-adjust: 109.35%
}.__className_ba7a58 {font-family: '__satoshi_ba7a58', '__satoshi_Fallback_ba7a58'
}.__variable_ba7a58 {--font-satoshi: '__satoshi_ba7a58', '__satoshi_Fallback_ba7a58'
}

/**
 * @license
 *
 * Font Family: Author
 * Designed by: Satya Rajpurohit
 * URL: https://www.fontshare.com/fonts/Author
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Author Variable(Variable font)
 * Author Variable Italic(Variable font)
 * Author Extralight
 * Author Extralight Italic
 * Author Light
 * Author Light Italic
 * Author Regular
 * Author Italic
 * Author Medium
 * Author Medium Italic
 * Author Semibold
 * Author Semibold Italic
 * Author Bold
 * Author Bold Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: "Author-Variable";
  src:
    url(/_next/static/media/Author-Variable.aa5a707c.woff2) format("woff2"),
    url(/_next/static/media/Author-Variable.97180dc8.woff) format("woff"),
    url(/_next/static/media/Author-Variable.5e32791a.ttf) format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: "Author-VariableItalic";
  src:
    url(/_next/static/media/Author-VariableItalic.d0786df0.woff2) format("woff2"),
    url(/_next/static/media/Author-VariableItalic.b3813836.woff) format("woff"),
    url(/_next/static/media/Author-VariableItalic.f4f8626d.ttf) format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Author-Extralight";
  src:
    url(/_next/static/media/Author-Extralight.e11c9b32.woff2) format("woff2"),
    url(/_next/static/media/Author-Extralight.b7510a54.woff) format("woff"),
    url(/_next/static/media/Author-Extralight.2befb1b6.ttf) format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Author-ExtralightItalic";
  src:
    url(/_next/static/media/Author-ExtralightItalic.5ad40484.woff2) format("woff2"),
    url(/_next/static/media/Author-ExtralightItalic.a9c42851.woff) format("woff"),
    url(/_next/static/media/Author-ExtralightItalic.2d040dbd.ttf) format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Author-Light";
  src:
    url(/_next/static/media/Author-Light.90a2afe1.woff2) format("woff2"),
    url(/_next/static/media/Author-Light.b31b1409.woff) format("woff"),
    url(/_next/static/media/Author-Light.948e3884.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Author-LightItalic";
  src:
    url(/_next/static/media/Author-LightItalic.5902f6ae.woff2) format("woff2"),
    url(/_next/static/media/Author-LightItalic.9c1222ef.woff) format("woff"),
    url(/_next/static/media/Author-LightItalic.4c57b1f0.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Author-Regular";
  src:
    url(/_next/static/media/Author-Regular.755edee4.woff2) format("woff2"),
    url(/_next/static/media/Author-Regular.2c011e56.woff) format("woff"),
    url(/_next/static/media/Author-Regular.8a2976c8.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Author-Italic";
  src:
    url(/_next/static/media/Author-Italic.61959e5f.woff2) format("woff2"),
    url(/_next/static/media/Author-Italic.80db6346.woff) format("woff"),
    url(/_next/static/media/Author-Italic.a549ae3c.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Author-Medium";
  src:
    url(/_next/static/media/Author-Medium.80c71291.woff2) format("woff2"),
    url(/_next/static/media/Author-Medium.c728a59f.woff) format("woff"),
    url(/_next/static/media/Author-Medium.6f69bead.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Author-MediumItalic";
  src:
    url(/_next/static/media/Author-MediumItalic.ca0a9a35.woff2) format("woff2"),
    url(/_next/static/media/Author-MediumItalic.0b77441d.woff) format("woff"),
    url(/_next/static/media/Author-MediumItalic.48a1df6d.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Author-Semibold";
  src:
    url(/_next/static/media/Author-Semibold.356a236f.woff2) format("woff2"),
    url(/_next/static/media/Author-Semibold.1bec54cd.woff) format("woff"),
    url(/_next/static/media/Author-Semibold.0f257a2c.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Author-SemiboldItalic";
  src:
    url(/_next/static/media/Author-SemiboldItalic.58b8a6e2.woff2) format("woff2"),
    url(/_next/static/media/Author-SemiboldItalic.ed72df8d.woff) format("woff"),
    url(/_next/static/media/Author-SemiboldItalic.b71b0dff.ttf) format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Author-Bold";
  src:
    url(/_next/static/media/Author-Bold.bd94cf59.woff2) format("woff2"),
    url(/_next/static/media/Author-Bold.93810d93.woff) format("woff"),
    url(/_next/static/media/Author-Bold.ad566806.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Author-BoldItalic";
  src:
    url(/_next/static/media/Author-BoldItalic.e1b72203.woff2) format("woff2"),
    url(/_next/static/media/Author-BoldItalic.e133f9d9.woff) format("woff"),
    url(/_next/static/media/Author-BoldItalic.a3278d1f.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/Satoshi
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Variable(Variable font)
 * Satoshi Variable Italic(Variable font)
 * Satoshi Light
 * Satoshi Light Italic
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: "Satoshi-Variable";
  src:
    url(/_next/static/media/Satoshi-Variable.d9396795.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Variable.5fada1b1.woff) format("woff"),
    url(/_next/static/media/Satoshi-Variable.867bce6e.ttf) format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:

* 'wght' (range from 300.0 to 900.0)

*/

@font-face {
  font-family: "Satoshi-VariableItalic";
  src:
    url(/_next/static/media/Satoshi-VariableItalic.7c85d340.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-VariableItalic.08edfc72.woff) format("woff"),
    url(/_next/static/media/Satoshi-VariableItalic.675122d2.ttf) format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Light";
  src:
    url(/_next/static/media/Satoshi-Light.d3f699ab.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Light.ce217c5d.woff) format("woff"),
    url(/_next/static/media/Satoshi-Light.121b151d.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-LightItalic";
  src:
    url(/_next/static/media/Satoshi-LightItalic.0d87c97a.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-LightItalic.51efbee6.woff) format("woff"),
    url(/_next/static/media/Satoshi-LightItalic.58b0e971.ttf) format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Regular";
  src:
    url(/_next/static/media/Satoshi-Regular.b1dca2a5.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Regular.bb2accee.woff) format("woff"),
    url(/_next/static/media/Satoshi-Regular.a12eb4fb.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-Italic";
  src:
    url(/_next/static/media/Satoshi-Italic.3eb4bb53.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Italic.43440d31.woff) format("woff"),
    url(/_next/static/media/Satoshi-Italic.84cd9c1d.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Medium";
  src:
    url(/_next/static/media/Satoshi-Medium.22539d17.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Medium.f3941e68.woff) format("woff"),
    url(/_next/static/media/Satoshi-Medium.8217b72e.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-MediumItalic";
  src:
    url(/_next/static/media/Satoshi-MediumItalic.17afee50.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-MediumItalic.5450477c.woff) format("woff"),
    url(/_next/static/media/Satoshi-MediumItalic.14c46485.ttf) format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Bold";
  src:
    url(/_next/static/media/Satoshi-Bold.12084922.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Bold.b28a04c4.woff) format("woff"),
    url(/_next/static/media/Satoshi-Bold.c60efc8f.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BoldItalic";
  src:
    url(/_next/static/media/Satoshi-BoldItalic.e51fcc53.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-BoldItalic.b59cf06f.woff) format("woff"),
    url(/_next/static/media/Satoshi-BoldItalic.c1d97e57.ttf) format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Black";
  src:
    url(/_next/static/media/Satoshi-Black.c6d20a6b.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-Black.28873509.woff) format("woff"),
    url(/_next/static/media/Satoshi-Black.12d5a2e3.ttf) format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BlackItalic";
  src:
    url(/_next/static/media/Satoshi-BlackItalic.5400951d.woff2) format("woff2"),
    url(/_next/static/media/Satoshi-BlackItalic.22c3e8d9.woff) format("woff"),
    url(/_next/static/media/Satoshi-BlackItalic.33bc16b8.ttf) format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

